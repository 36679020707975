<template>
  <div>
    Connecting api.. please wait..<br>
    {{this.ret}}
  </div>
</template>

<script>
  import {
    mapState,
  } from 'vuex'
  function getCookie (name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }
  export default {
    name: 'ConnectApi',
    data () {
      return {
        ret: undefined,
      }
    },
    computed: {
      ...mapState('route', ['query']),
      ...mapState('platform', ['mainHost']),
    },
    async mounted () {
      // got x, now send to server to assign
      this.ret = this.query.code
      var seshId = getCookie('ws_id')
      var code = this.query.code
      var state = this.query.state
      var ret = (await this.$http.get(this.mainHost + '/connect_api?seshId=' + seshId + '&code=' + code + '&state=' + state)).data
      this.ret = ret
    },
  }
</script>
